import { FormControl, Tooltip } from '@chakra-ui/react';
import get from 'lodash/get';
import type { FieldErrors, FieldPath, FieldValues } from 'react-hook-form';
import RhfFormErrorMessage from './RhfFormErrorMessage';

type Props<T extends FieldValues> = {
  errors: FieldErrors<T>;
  name: FieldPath<T>;
  children: React.ReactNode;
  mode?: 'inline' | 'tooltip';
};
export default function RhfFormControlWithErrorMessage<T extends FieldValues>({
  children,
  errors,
  name,
  mode = 'inline',
}: Props<T>) {
  const error = get(errors, name);

  switch (mode) {
    case 'inline':
      return (
        <FormControl isInvalid={error !== undefined}>
          {children}
          <RhfFormErrorMessage errors={errors} name={name} />
        </FormControl>
      );
    case 'tooltip':
      return (
        <FormControl isInvalid={error !== undefined}>
          <Tooltip
            label={error?.message as string}
            placement='top'
            isOpen={!!error}
            colorScheme='red'
            bgColor='red'
            hasArrow
          >
            {children}
          </Tooltip>
        </FormControl>
      );
  }
}
