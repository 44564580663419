import { Text } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import type { ComponentProps } from 'react';

type Props = Omit<ComponentProps<typeof ErrorMessage>, 'render'>;
export default function RhfFormErrorMessage(props: Props) {
  return (
    <ErrorMessage
      {...props}
      render={({ message }) => {
        return (
          message && (
            <Text color='red' fontWeight='semibold'>
              {message}
            </Text>
          )
        );
      }}
    />
  );
}
